import { ColorsEnum } from "../../core/enums/enums";

interface RadioButtonProps extends React.InputHTMLAttributes<HTMLInputElement> {
  color?: ColorsEnum;
}

export function RadioButton({
  color = ColorsEnum.Primary,
  ...props
}: RadioButtonProps): JSX.Element {
  return (
    <div dir={props.dir}>
      <label className="inline-flex items-center">
        <input
          {...props}
          type="radio"
          className={`form-radio checked:bg-${color} border checked:border-transparent`}
        />
        <span className="mx-2 font mb-1 inline-block text-sm text-slate-700 dark:text-white">
          {props["aria-label"]}
        </span>
      </label>
    </div>
  );
}
