import DropDownMenuButtonItem from "../dropdown/dropdown-menu-button-item";
import { PiSignOut } from "react-icons/pi";
import { DirectionEnum } from "../../core/enums/enums";

interface HeaderMenuProps {
  handleLogOut: () => void;
}

export function HeaderMenu({ handleLogOut }: HeaderMenuProps) {
  return (
    <div className="flex w-full flex-col items-center gap-2 justify-center">
      <DropDownMenuButtonItem
        label="Logout"
        labelColorClass="text-danger hover:text-danger"
        action={() => handleLogOut()}
        backgroundColorClass="hover:bg-danger/10"
        icon={<PiSignOut size={20} />}
        dir={DirectionEnum.LTR}
      />
    </div>
  );
}

export default HeaderMenu;
