import { useEffect, useState } from "react";

const getColorByPercentage = (
  percentage: number | undefined
): string => {
  if (percentage === undefined) return "#A5348A";
  // red
  if (percentage <= 40) return "#E53F3F";
  // orange
  if (percentage >= 41 && percentage <= 69) return "#EC7100";
  // green
  if (percentage >= 70) return "#40AFA8";

  return "#E53F3F";
};

export type ProgressChartProps = {
  percentage: number | undefined;
  value?: string;
  title: string;
};

export default function ProgressChart({
  percentage,
  value,
  title,
}: ProgressChartProps) {
  const [currentProgress, setCurrentProgress] = useState(0);
  const [progress, setProgress] = useState(0);
  const colorByPercentage = getColorByPercentage(percentage);

  setTimeout(() => {
    setCurrentProgress(percentage || 0);
  }, 200);

  //   increment from 0 to percentage
  useEffect(() => {
    const id = setInterval(() => {
      setProgress((oldVal) => {
        if (oldVal === 100) {
          clearInterval(id);
          return 100;
        }

        if (oldVal < (percentage || 0)) {
          return oldVal + 1;
        }
        clearInterval(id);
        return oldVal;
      });
    }, 50);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <div className="relative m-auto h-[140px] w-[140px]">
        <svg
          className="size-full -rotate-90"
          viewBox="0 0 36 36"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="18"
            cy="18"
            r="16"
            fill="none"
            className="stroke-current"
            style={{
              color: colorByPercentage,
              opacity: "0.1",
            }}
            strokeWidth="3"
          ></circle>
          <circle
            cx="18"
            cy="18"
            r="16"
            fill="none"
            className="stroke-current transition-all duration-[2000ms] ease-linear"
            style={{
              color: colorByPercentage,
            }}
            strokeWidth="3"
            strokeDasharray="100"
            strokeDashoffset={`${100 - currentProgress}`}
          ></circle>
        </svg>

        <div className="absolute left-0 right-0 top-[55px] text-center">
          <span
            className="text-center text-2xl font-bold"
            style={{
              color: colorByPercentage,
            }}
          >
            {progress ? progress : "--"}%
          </span>
        </div>
      </div>

      <h6 className="whitespace-nowrap text-center text-xs text-muted-400">
        {title}
      </h6>

      {value && <h4 className="text-center text-3xl font-bold">{value}</h4>}
    </div>
  );
}
