import {
  AdminAttendanceUsersType,
  SeedType,
  Token,
  UpdateUserType,
} from "../../core/types/types";
import { useState, useEffect, useCallback } from "react";
import { toast_error } from "../../core/hooks/toast_error";
import ClipLoader from "react-spinners/ClipLoader";
import {
  fetchAdminAttendanceUsersService,
  fetchAdminSeedService,
  updateUsersService,
} from "../../core/services/shared.service";
import { Navigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Header from "../header/header";
import Footer from "../footer";
import SharedPagination from "../pagination/pagination";
import { Select } from "../select/select";
import {
  ColorsEnum,
  DirectionEnum,
  SizesEnum,
  VariantsEnum,
} from "../../core/enums/enums";
import { toast } from "react-toastify";
import { Button } from "../button";

export default function AttendanceEditUsers() {
  const [attendanceData, setAttendanceData] = useState<
    AdminAttendanceUsersType[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [seed, setSeed] = useState<SeedType>();
  const token = sessionStorage.getItem("access_token") as string;
  const decodedToken = jwt_decode(token) as Token;
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [usersUpdatedData, setUsersUpdatedData] = useState<UpdateUserType[]>(
    []
  );
  const limit = 100;

  const fetchAttendance = useCallback(async () => {
    setLoading(true);
    try {
      const res = await fetchAdminAttendanceUsersService({
        limit: limit,
        offset: limit * (currentPage - 1),
      });
      const seedRes = await fetchAdminSeedService();

      setAttendanceData(res.result_list);
      setUsersUpdatedData(
        res.result_list?.map((r: AdminAttendanceUsersType) => {
          return {
            id: r.id,
            full_name: r.full_name,
            email: r.email,
            mobile: r.mobile,
            is_leader: r.is_leader,
            zone: r.zone,
            team: r.team,
            table_: r.table_,
          };
        })
      );
      setTotalCount(res.total_count);
      setSeed(seedRes);
    } catch (error) {
      toast_error(error);
    } finally {
      setLoading(false);
    }
  }, [currentPage]);

  const handleUpdateUsers = async () => {
    setLoading(true);
    try {
      await updateUsersService(usersUpdatedData);
      toast.success("Updated");
      fetchAttendance();
    } catch (error) {
      toast_error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAttendance();
  }, [fetchAttendance]);

  if (!decodedToken.is_admin) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="h-screen">
      <div className="min-h-screen bg-hero bg-right-top bg-no-repeat bg-contain max-sm:pt-32">
        {/* HEADER */}
        <Header />

        <div className="lg:max-w-7xl md:max-w-sm flex lg:flex-row flex-col items-start gap-6 max-w-xs mx-auto w-full lg:mt-20">
          <div className="w-full">
            <div className="flex gap-4 flex-col mb-6">
              <div className="flex lg:flex-row flex-col gap-2 items-center justify-between w-full">
                <p className="text-black font-bold lg:text-4xl">Users</p>
              </div>
              {loading ? (
                <div className="w-full text-center">
                  <ClipLoader className="mx-auto mt-10" />
                </div>
              ) : (
                <>
                  <div className="text-[#64748B] font-bold text-sm">
                    Result : {totalCount}
                  </div>
                  <div className="w-full flex items-center justify-end mb-4">
                    <Button
                      color={ColorsEnum.Primary}
                      variant={VariantsEnum.Filled}
                      size={SizesEnum.Medium}
                      type="button"
                      className="font-bold !rounded-3xl"
                      disabled={loading}
                      onClick={handleUpdateUsers}
                    >
                      Save
                    </Button>
                  </div>

                  {attendanceData?.length > 0 ? (
                    attendanceData?.map((member, i) => (
                      <div
                        className="rounded-2xl group cursor-pointer w-full flex flex-col gap-3 px-5 py-4 border border-[#E2E2E2] hover:border-white h-full hover:bg-white bg-[#F2F5F7] transition-all duration-300"
                        key={`attendanceData-${i}`}
                      >
                        <div className="flex w-full items-center justify-between lg:flex-row flex-col gap-3">
                          <p className="font-bold text-black text-xl">
                            {member.full_name}{" "}
                            {member.is_leader && (
                              <span className="px-2 py-1 rounded-full font-light w-fit text-secondary bg-secondary/10 text-[10px]">
                                Leader
                              </span>
                            )}
                          </p>
                          <p className="px-3 py-1 rounded-full w-fit text-muted bg-muted/10 text-xs">
                            {member.mobile}
                          </p>
                        </div>
                        <div className="grid lg:grid-cols-3 grid-cols-1 items-center gap-2">
                          <Select
                            aria-label="Zone"
                            listToDisplay={seed?.zones || []}
                            onChange={(e) => {
                              const newArr = usersUpdatedData?.map((a) => {
                                const foundUser = a.id === member.id;

                                if (foundUser) {
                                  return {
                                    ...a,
                                    zone: e.target.value,
                                  };
                                } else {
                                  return a;
                                }
                              });

                              setUsersUpdatedData(newArr);
                            }}
                            dir={DirectionEnum.LTR}
                            defaultValue={member.zone}
                          />
                          <Select
                            aria-label="Table"
                            listToDisplay={seed?.tables || []}
                            onChange={(e) => {
                              const newArr = usersUpdatedData?.map((a) => {
                                const foundUser = a.id === member.id;

                                if (foundUser) {
                                  return {
                                    ...a,
                                    table_: e.target.value,
                                  };
                                } else {
                                  return a;
                                }
                              });

                              setUsersUpdatedData(newArr);
                            }}
                            dir={DirectionEnum.LTR}
                            defaultValue={member.table_}
                          />
                          <Select
                            aria-label="Team"
                            listToDisplay={seed?.teams || []}
                            onChange={(e) => {
                              const newArr = usersUpdatedData?.map((a) => {
                                const foundUser = a.id === member.id;

                                if (foundUser) {
                                  return {
                                    ...a,
                                    team: e.target.value,
                                  };
                                } else {
                                  return a;
                                }
                              });

                              setUsersUpdatedData(newArr);
                            }}
                            dir={DirectionEnum.LTR}
                            defaultValue={member.team}
                          />
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="w-full text-center">No Data</div>
                  )}

                  <div className="w-full flex items-center justify-end mb-4">
                    <SharedPagination
                      currentPage={currentPage}
                      onPageClick={(page) => setCurrentPage(page)}
                      totalListCount={totalCount}
                      limit={limit}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* FOOTER */}
      <Footer />
    </div>
  );
}
