export default function Footer() {
  return (
    <div>
      <div className="p-4 bg-primary w-full">
        <p className="text-white font-bold text-center text-sm">
          all rights reserved © {new Date().getFullYear()} ©
        </p>
      </div>
    </div>
  );
}
