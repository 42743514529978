import { useEffect } from "react";
import {  useNavigate } from "react-router-dom";
import { PiSignOut, PiUserCircleLight } from "react-icons/pi";
import { Token } from "../../core/types/types";
import { FiChevronRight, FiMenu, FiUserPlus } from "react-icons/fi";
import logo from "../../assets/logo.svg";
import { HiX } from "react-icons/hi";
import { ColorsEnum, VariantsEnum, SizesEnum } from "../../core/enums/enums";
import { Button } from "../button";
import jwt from "jwt-decode";
import { isExpired } from "react-jwt";

interface HeaderMobileProps {
  isCollapsed: boolean;
  setIsCollapsed: (val: boolean) => void;
  handleLogOut: () => void;
}

export default function HeaderMobile({
  isCollapsed,
  setIsCollapsed,
  handleLogOut,
}: HeaderMobileProps) {
  const navigate = useNavigate();
  const token = sessionStorage.getItem("access_token");
  const isLoggedIn = token ? !isExpired(token) : false;

  useEffect(() => {
    if (!isCollapsed) {
      // disable body scrolling behaviour
      window.document.body.style.setProperty("overflow", "hidden", "important");
      window.document
        .getElementsByTagName("html")[0]
        .style.setProperty("overflow", "hidden", "important");
      window.document.body.style.width = "100%";
    } else {
      // enable body scrolling behaviour
      window.document.body.style.overflow = "auto";
      window.document.getElementsByTagName("html")[0].style.overflow = "auto";
    }
  }, [isCollapsed]);

  useEffect(() => {
    return () => {
      setIsCollapsed(true);
      window.document.body.style.position = "unset";
    };
  }, [setIsCollapsed]);

  return (
    <div className="z-[100] flex w-full items-center justify-between">
      <div
        className={`absolute inset-x-0 max-w-[352px] top-20 mx-auto grid w-full overflow-hidden rounded-xl bg-white transition-all duration-700 sm:top-28 ${
          isCollapsed ? "grid-rows-[0fr]" : "grid-rows-[1fr]"
        }`}
      >
        <div className="min-h-0 w-full overflow-hidden">
          <div className="flex w-full flex-col items-center justify-center gap-8 py-8 text-slate-600">
            <div className="mx-auto mt-0 flex max-w-[352px] w-full flex-col gap-8 justify-center pt-3">
              {isLoggedIn && token ? (
                <>
                  <Button
                    color={ColorsEnum.Muted}
                    variant={VariantsEnum.Outline}
                    size={SizesEnum.Medium}
                    className="font-bold !rounded-3xl w-fit mx-auto"
                  >
                    <div className="flex items-center gap-2 justify-center">
                      <PiUserCircleLight size={20} />
                      <span>{(jwt(token) as Token).email.split("@")[0]}</span>
                      <FiChevronRight size={20} />
                    </div>
                  </Button>
                  <Button
                    color={ColorsEnum.Danger}
                    variant={VariantsEnum.Filled}
                    size={SizesEnum.Medium}
                    onClick={() => handleLogOut()}
                    className="font-bold !rounded-3xl w-fit mx-auto"
                  >
                    <div className="flex items-center gap-2 justify-center">
                      <PiSignOut size={20} />
                      <span>Logout</span>
                    </div>
                  </Button>
                </>
              ) : (
                <div className="flex flex-col gap-2">
                  <Button
                    color={ColorsEnum.Muted}
                    variant={VariantsEnum.Muted}
                    size={SizesEnum.Medium}
                    onClick={() => navigate("/login")}
                    className="font-bold !rounded-3xl  w-fit mx-auto"
                  >
                    <div className="flex items-center gap-1 justify-center">
                      <span>Login</span>
                      <FiUserPlus />
                    </div>
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="relative justify-between z-50 w-full flex items-center gap-2">
        <img
          src={logo}
          alt="Allam | علّام"
          className="h-12 w-11"
          loading="lazy"
          onClick={() => {
            setIsCollapsed(true);
          }}
        />
        <button
          title="menu"
          type="button"
          className="text-gray-500 dark:text-white"
          onClick={() => {
            setIsCollapsed(!isCollapsed);
          }}
        >
          <div className="w-10 items-center bg-red-400"></div>
          <HiX
            className={`absolute top-[10px] transition-all duration-300 ${
              !isCollapsed ? "opacity-100" : "opacity-0"
            }`}
            size={30}
          />
          <FiMenu
            className={`absolute top-[10px] transition-all duration-300 ${
              !isCollapsed ? "opacity-0" : "opacity-100"
            }`}
            size={30}
          />
        </button>
      </div>
    </div>
  );
}
