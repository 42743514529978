export const baseURL = process.env["REACT_APP_BACKEND_BASE_URL"];

type URLs = {
  shared: {
    login: string;
    getUser: string;
    getAllAttendance: string;
    getAdminAllAttendance: string;
    getAdminUsersAttendance: string;
    updateAttendanceStatus: string;
    seed: string;
  };
};

// Export an object that contains all the app's URLs
export const urls: URLs = {
  shared: {
    login: `${baseURL}/user/login`,
    getUser: `${baseURL}/user/info`,
    getAllAttendance: `${baseURL}/user`,
    getAdminAllAttendance: `${baseURL}/hackathon`,
    getAdminUsersAttendance: `${baseURL}/user/participants`,
    updateAttendanceStatus: `${baseURL}/user/attendance`,
    seed: `${baseURL}/seed`,
  },
};
