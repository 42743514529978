import { useState } from "react";
import { toast_error } from "../../core/hooks/toast_error";
import { useNavigate } from "react-router-dom";
import {
  ColorsEnum,
  DirectionEnum,
  SizesEnum,
  VariantsEnum,
} from "../../core/enums/enums";
import { useFormik } from "formik";
import { validationSchema } from "./login-container.validation";
import { Button } from "../../components/button";
import { InputField } from "../../components/input-field/input-field";
import { FiChevronRight } from "react-icons/fi";
import { loginService } from "../../core/services/shared.service";
import { PiEye, PiEyeClosed } from "react-icons/pi";
import { Token } from "../../core/types/types";
import jwt_decode from "jwt-decode";

export default function LoginContainer() {
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: localStorage.getItem("email") ?? "",
      password: "",
    },
    onSubmit: () => {
      handleSubmit();
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    validateOnBlur: true,
  });

  const handleSubmit = async () => {
    try {
      const res = await loginService(formik.values);
      sessionStorage.setItem("access_token", res.access_token);
      const decodedToken = jwt_decode(res.access_token) as Token;
      const redirectAfterLogin = sessionStorage.getItem("redirect_after_login");
      if (redirectAfterLogin) {
        navigate(redirectAfterLogin);
        sessionStorage.removeItem("redirect_after_login");
      } else {
        if (decodedToken.is_admin) {
          navigate("/admin");
        } else {
          navigate("/dashboard");
        }
      }
    } catch (error) {
      toast_error(error);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit} className="w-full">
      <div className="lg:w-1/2 mx-auto w-full h-full flex flex-col gap-6 flex-shrink-0">
        <p className="text-black font-bold text-4xl">Log in</p>
        <div className="p-8 bg-white w-full rounded-[20px] flex flex-col gap-6">
          <InputField
            placeholder="email@email.com"
            aria-label="Email"
            name="email"
            type="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            dir={DirectionEnum.LTR}
            value={formik.values.email.trimStart() ?? ""}
            isValid={formik.touched.email && !formik.errors.email}
            error={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : ""
            }
          />
          <InputField
            placeholder="**********"
            aria-label="Password"
            type={isPasswordVisible ? "text" : "password"}
            name="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            dir={DirectionEnum.LTR}
            endIcon={
              isPasswordVisible ? (
                <PiEye
                  className="cursor-pointer"
                  onClick={() => setIsPasswordVisible(false)}
                />
              ) : (
                <PiEyeClosed
                  className="cursor-pointer"
                  onClick={() => setIsPasswordVisible(true)}
                />
              )
            }
            value={formik.values.password.trimStart() ?? ""}
            isValid={formik.touched.password && !formik.errors.password}
            error={
              formik.touched.password && formik.errors.password
                ? formik.errors.password
                : ""
            }
          />

          {/* ACTIONS */}
          <div className="flex justify-end w-full">
            <Button
              color={ColorsEnum.Primary}
              variant={VariantsEnum.Filled}
              size={SizesEnum.Medium}
              type="submit"
              className="font-bold !rounded-3xl"
            >
              <div className="flex items-center text-white gap-1 justify-center">
                <span>Log in</span>
                <FiChevronRight />
              </div>
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}
