import {
  MembersData,
  Token,
  UpdateMembersStatusType,
} from "../../core/types/types";
import { useState, useEffect, useCallback } from "react";
import { toast_error } from "../../core/hooks/toast_error";
import ClipLoader from "react-spinners/ClipLoader";
import {
  fetchAttendanceListService,
  updateAttendanceStatusService,
} from "../../core/services/shared.service";
import SearchInput from "../search";
import { RadioButton } from "../radio-button/radio-button";
import { Navigate, useLocation } from "react-router-dom";
import { AttendanceStatusEnum } from "../../core/enums/enums";
import SharedPagination from "../pagination/pagination";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";

export default function AttendanceList() {
  const [attendanceData, setAttendanceData] = useState<MembersData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const location = useLocation();
  const [search, setSearch] = useState<string>("");
  const [attendanceStatus, setAttendanceStatus] = useState<
    UpdateMembersStatusType[]
  >([]);
  const url = new URLSearchParams(location.search);
  const period = url.get("period") || "1";
  const limit = 100;
  const token = sessionStorage.getItem("access_token") as string;
  const decodedToken = jwt_decode(token) as Token;

  const fetchAttendance = useCallback(async () => {
    setLoading(true);
    try {
      const url = new URLSearchParams(location.search);
      const date = url.get("date") || "2024-11-07";
      const period = url.get("period") || "1";
      const res = await fetchAttendanceListService({
        limit: limit,
        offset: limit * (currentPage - 1),
        q: search,
        date: date,
        period: parseInt(period),
      });

      setAttendanceData(res.result_list);
      setAttendanceStatus(
        res.result_list.map((e: MembersData) => ({
          user_id: e.id,
          attendance_status: e.attendance_status,
        }))
      );
      setTotalCount(res.total_count);
    } catch (error) {
      toast_error(error);
    } finally {
      setLoading(false);
    }
  }, [currentPage, location.search, search]);

  const handleUpdateAttendance = async (data: UpdateMembersStatusType) => {
    setIsSubmitting(true);
    try {
      const url = new URLSearchParams(location.search);
      const date = url.get("date") || "2024-11-07";
      const period = url.get("period") || "1";
      await updateAttendanceStatusService([data], {
        date: date,
        period: parseInt(period),
      });
      toast.success("Updated");
      fetchAttendance();
    } catch (error) {
      toast_error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    fetchAttendance();
  }, [fetchAttendance]);


  if (decodedToken.is_admin) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="w-full">
      <div className="flex gap-4 flex-col mb-6">
        <div className="flex lg:flex-row flex-col gap-2 items-center justify-between w-full">
          <p className="text-black font-bold lg:text-4xl">
            Attendance{" "}
            <span className="font-bold px-3 py-1 rounded-full w-fit text-secondary bg-secondary/10 text-sm">
              Zone: {(decodedToken as Token)?.zone}
            </span>
          </p>
          <SearchInput
            value={search}
            onSearchValueChange={(value) => {
              setSearch(value);
              setCurrentPage(1);
            }}
          />
        </div>
        {loading ? (
          <div className="w-full text-center">
            <ClipLoader className="mx-auto mt-10" />
          </div>
        ) : (
          <>
            <div className="text-[#64748B] font-bold text-sm">
              Result : {totalCount}
            </div>

            {attendanceData?.length > 0 ? (
              attendanceData?.map((member, i) => (
                <div
                  className="rounded-2xl group cursor-pointer w-full flex flex-col gap-3 px-5 py-4 border border-[#E2E2E2] hover:border-white h-full hover:bg-white bg-[#F2F5F7] transition-all duration-300"
                  key={`attendanceData-${i}`}
                >
                  <p className="text-primary text-xs">
                    Team: <span className="font-bold">{member.team}</span> |
                    Table: <span className="font-bold">{member.table}</span>
                  </p>
                  <p className="font-bold text-black text-xl">
                    {member.name}{" "}
                    {member.is_leader && (
                      <span className="px-2 py-1 rounded-full font-light w-fit text-secondary bg-secondary/10 text-[10px]">
                        Leader
                      </span>
                    )}
                  </p>
                  <p className="px-3 py-1 rounded-full w-fit text-muted bg-muted/10 text-xs">
                    {member.mobile}
                  </p>
                  <div className="grid lg:grid-cols-5 grid-cols-1 items-center gap-2">
                    <div className="lg:col-span-3 flex gap-2 items-center">
                      {member?.periods_status
                        ?.filter((p) => p.status !== null)
                        ?.map((period, periodIndex) => {
                          return (
                            <div
                              className="text-sm text-slate-400 font-light"
                              key={`period-${periodIndex}-${member.id}`}
                            >
                              Period #{periodIndex + 1} :{" "}
                              {period.status === AttendanceStatusEnum.ABSENT &&
                                "❌"}
                              {period.status === AttendanceStatusEnum.PRESENT &&
                                "✅"}
                              {period.status === null && "--"}
                            </div>
                          );
                        })}
                    </div>
                    <p className="text-primary text-sm font-light lg:text-end">
                      Period #{period} :
                    </p>
                    <div className="inline-flex gap-1 font-bold">
                      {Object.values(AttendanceStatusEnum).map(
                        (item: AttendanceStatusEnum) => {
                          return (
                            <RadioButton
                              key={item}
                              disabled={isSubmitting}
                              name={`${member.id}-attendance`}
                              value={item}
                              aria-label={item.toLocaleLowerCase()}
                              onChange={() => {
                                const newArr = [...attendanceStatus];
                                const index = newArr.findIndex(
                                  (x) => x.user_id === member.id
                                );
                                newArr[index].attendance_status = item;
                                setAttendanceStatus(newArr);
                                handleUpdateAttendance({
                                  user_id: member.id,
                                  attendance_status: item,
                                });
                              }}
                              checked={
                                attendanceStatus.find(
                                  (x) => x.user_id === member.id
                                )?.attendance_status === item
                              }
                            />
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="w-full text-center">No Data</div>
            )}

            <div className="w-full flex items-center justify-end mb-4">
              <SharedPagination
                currentPage={currentPage}
                onPageClick={(page) => setCurrentPage(page)}
                totalListCount={totalCount}
                limit={limit}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
