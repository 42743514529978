import { makeRequest, urls } from "../http";
import { removeFalsyValues } from "../hooks/shared.helpers";
import { HttpMethods } from "../enums/httpMethods.enum";
import {
  DynamicValuesType,
  LogInData,
  UpdateMembersStatusType,
  UpdateUserType,
} from "../types/types";

export const fetchAttendanceListService = (params?: DynamicValuesType) => {
  return makeRequest({
    url: urls.shared.getAllAttendance,
    method: HttpMethods.GET,
    params: { ...removeFalsyValues(params || {}) },
  });
};

export const fetchAdminAttendanceListService = (params?: DynamicValuesType) => {
  return makeRequest({
    url: urls.shared.getAdminAllAttendance,
    method: HttpMethods.GET,
    params: { ...removeFalsyValues(params || {}) },
  });
};

export const fetchAdminAttendanceUsersService = (
  params?: DynamicValuesType
) => {
  return makeRequest({
    url: urls.shared.getAdminUsersAttendance,
    method: HttpMethods.GET,
    params: { ...removeFalsyValues(params || {}) },
  });
};

export const fetchAdminSeedService = () => {
  return makeRequest({
    url: urls.shared.seed,
    method: HttpMethods.GET,
  });
};

export const fetchUserDataService = () => {
  return makeRequest({
    url: urls.shared.getUser,
    method: HttpMethods.GET,
  });
};

export const loginService = (data: LogInData) => {
  return makeRequest({
    url: urls.shared.login,
    method: HttpMethods.POST,
    data,
  });
};

export const updateAttendanceStatusService = (
  data: UpdateMembersStatusType[],
  params?: DynamicValuesType
) => {
  return makeRequest({
    url: urls.shared.updateAttendanceStatus,
    method: HttpMethods.PUT,
    data,
    params: { ...removeFalsyValues(params || {}) },
  });
};

export const updateUsersService = (data: UpdateUserType[]) => {
  return makeRequest({
    url: urls.shared.getAdminUsersAttendance,
    method: HttpMethods.PUT,
    data,
  });
};
