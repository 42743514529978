import { Navigate } from "react-router-dom";
import RefreshTokenUpdateWrapperContainer from "../refresh-token-update-wrapper-container/refresh-token-update-wrapper-container";

interface AuthAccessWrapperContainerProps {
  children: JSX.Element;
}

const AuthAccessWrapperContainer = ({
  children,
}: AuthAccessWrapperContainerProps) => {
  const token = sessionStorage.getItem("access_token") as string;

  if (!token) {
    const currentPath = window.location.pathname;
    sessionStorage.setItem("redirect_after_login", currentPath);

    return <Navigate to="/login" />;
  }

  return (
    <RefreshTokenUpdateWrapperContainer>
      {children}
    </RefreshTokenUpdateWrapperContainer>
  );
};

export default AuthAccessWrapperContainer;
