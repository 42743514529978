import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useIsMobileScreen from "../../core/hooks/use-is-mobile-screen.helpers";
import HandelOutSideClick from "../../core/hooks/handel-outside-click.helpers";
import HeaderDesktop from "./header.desktop";
import HeaderMobile from "./header.mobile";

export default function Header() {
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const navRef = useRef(null);

  const { isMobile } = useIsMobileScreen();

  const handleLogOut = () => {
    sessionStorage.setItem("access_token", "");
    navigate("/login");
  };

  return (
    <>
      <div
        className={`fixed ${
          !isCollapsed ? "z-[60] backdrop-blur-md backdrop-filter" : "-z-50"
        } inset-x-0 mx-auto top-0 h-screen !w-full bg-black/5 transition-all duration-300`}
      />
      <nav
        ref={navRef}
        className={`z-[100] mx-auto flex h-16 w-full items-center justify-center bg-white sm:h-24 sm:max-w-2xl lg:max-w-7xl ${
          !isMobile
            ? "sm:bg-transparent"
            : `fixed inset-x-0 top-0 mt-5 max-w-[352px] rounded-xl px-6`
        }`}
      >
        {isMobile ? (
          <HeaderMobile
            isCollapsed={isCollapsed}
            setIsCollapsed={setIsCollapsed}
            handleLogOut={handleLogOut}
          />
        ) : (
          <HeaderDesktop handleLogOut={handleLogOut} />
        )}
      </nav>
      <HandelOutSideClick
        isOpen={!isCollapsed}
        targetRef={navRef}
        OnOutsideClick={() => {
          setIsCollapsed(true);
        }}
      />
    </>
  );
}
