import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import ToastMessage from "./toast-message";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "./screens/dashboard";
import LoginPage from "./screens/login-page";
import AttendanceList from "./components/dashboard/attendance_list";
import AuthAccessWrapperContainer from "./containers/auth-access-wrapper-container/auth-access-wrapper-container";
import AttendanceAdmin from "./components/dashboard/attendance_admin";
import AttendanceEditUsers from "./components/dashboard/attendance_edit_users";

import "./index.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

export default function App() {
  const withAuth = (Component: JSX.Element) => {
    return <AuthAccessWrapperContainer>{Component}</AuthAccessWrapperContainer>;
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/dashboard" element={withAuth(<Dashboard />)}>
          <Route path="/dashboard/attendances" element={<AttendanceList />} />
          <Route path="*" element={<Navigate to="/dashboard/attendances" />} />
          <Route index element={<Navigate to="/dashboard/attendances" />} />
        </Route>
        <Route path="/admin" element={<AttendanceAdmin />} />
        <Route path="/admin/users" element={<AttendanceEditUsers />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </BrowserRouter>
  );
}

root.render(
  <React.StrictMode>
    <App />
    <ToastMessage />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
