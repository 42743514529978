import Header from "../components/header/header";
import Footer from "../components/footer";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { DirectionEnum } from "../core/enums/enums";
import { Select } from "../components/select/select";

export default function Dashboard() {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className="h-screen">
      <div className="min-h-screen bg-hero bg-right-top bg-no-repeat bg-contain max-sm:pt-32">
        {/* HEADER */}
        <Header />

        <div className="lg:max-w-7xl md:max-w-sm flex lg:flex-row flex-col items-start gap-6 max-w-xs mx-auto w-full lg:mt-20">
          <div className="lg:w-[312px] w-full h-full flex flex-col gap-6 flex-shrink-0">
            <div className="p-4 bg-white w-full rounded-[20px]">
              <div className="flex flex-col gap-1">
                <div className="flex flex-col gap-1">
                  <p className="text-black text-[10px] font-bold">
                    Select Day:
                  </p>
                  <Select
                    listToDisplay={["2024-11-07", "2024-11-08", "2024-11-09"]}
                    onChange={(e) => {
                      const url = new URLSearchParams(location.search);
                      url.delete("date");
                      url.append("date", e.target.value);
                      navigate(
                        {
                          pathname: location.pathname,
                          search: url.toString(),
                        },
                        { replace: true }
                      );
                    }}
                    dir={DirectionEnum.LTR}
                    value={
                      new URLSearchParams(location.search).get("date") ||
                      "2024-11-07"
                    }
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <p className="text-black text-[10px] font-bold">
                    Select Period:
                  </p>
                  <Select
                    listToDisplay={["1", "2", "3"]}
                    onChange={(e) => {
                      const url = new URLSearchParams(location.search);
                      url.delete("period");
                      url.append("period", e.target.value);
                      navigate(
                        {
                          pathname: location.pathname,
                          search: url.toString(),
                        },
                        { replace: true }
                      );
                    }}
                    dir={DirectionEnum.LTR}
                    value={
                      new URLSearchParams(location.search).get("period") || "1"
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full">
            <Outlet />
          </div>
        </div>
      </div>

      {/* FOOTER */}
      <Footer />
    </div>
  );
}
