import { AdminAttendanceType, Token } from "../../core/types/types";
import { useState, useEffect, useCallback } from "react";
import { toast_error } from "../../core/hooks/toast_error";
import ClipLoader from "react-spinners/ClipLoader";
import { fetchAdminAttendanceListService } from "../../core/services/shared.service";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { DirectionEnum } from "../../core/enums/enums";
import jwt_decode from "jwt-decode";
import Header from "../header/header";
import Footer from "../footer";
import { Select } from "../select/select";
import ProgressChart from "../progress-chart";

export default function AttendanceAdmin() {
  const navigate = useNavigate();
  const [attendanceData, setAttendanceData] = useState<AdminAttendanceType[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(true);
  const location = useLocation();
  const token = sessionStorage.getItem("access_token") as string;
  const decodedToken = jwt_decode(token) as Token;

  const fetchAttendance = useCallback(async () => {
    setLoading(true);
    try {
      const url = new URLSearchParams(location.search);
      const date = url.get("date") || "2024-11-07";
      const res = await fetchAdminAttendanceListService({
        date: date,
      });

      setAttendanceData(res);
    } catch (error) {
      toast_error(error);
    } finally {
      setLoading(false);
    }
  }, [location.search]);

  useEffect(() => {
    fetchAttendance();
  }, [fetchAttendance]);

  if (!decodedToken.is_admin) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="h-screen">
      <div className="min-h-screen bg-hero bg-right-top bg-no-repeat bg-contain max-sm:pt-32">
        {/* HEADER */}
        <Header />

        <div className="lg:max-w-7xl md:max-w-sm flex lg:flex-row flex-col items-start gap-6 max-w-xs mx-auto w-full lg:mt-20">
          <div className="w-full">
            <div className="flex gap-4 flex-col mb-6">
              <div className="flex lg:flex-row flex-col gap-2 items-center justify-between w-full">
                <p className="text-black font-bold lg:text-4xl text-xl">Attendance</p>
                <div className="lg:w-1/4 w-full">
                  <Select
                    aria-label="Select Day:"
                    listToDisplay={["2024-11-07", "2024-11-08", "2024-11-09"]}
                    onChange={(e) => {
                      const url = new URLSearchParams(location.search);
                      url.delete("date");
                      url.append("date", e.target.value);
                      navigate(
                        {
                          pathname: location.pathname,
                          search: url.toString(),
                        },
                        { replace: true }
                      );
                    }}
                    dir={DirectionEnum.LTR}
                    value={
                      new URLSearchParams(location.search).get("date") ||
                      "2024-11-07"
                    }
                  />
                </div>
              </div>
              {loading ? (
                <div className="w-full text-center">
                  <ClipLoader className="mx-auto mt-10" />
                </div>
              ) : (
                <>
                  <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 items-center">
                    {attendanceData?.map((a, index) => {
                      return (
                        <div
                          className="rounded-2xl group justify-between cursor-pointer w-full flex flex-col gap-6 p-5 border border-[#E2E2E2] hover:border-white h-full hover:bg-white bg-[#F2F5F7] transition-all duration-300"
                          key={`attendanceData-${index}`}
                        >
                          <p className="text-black font-bold lg:text-4xl text-xl">
                            Zone {a.zone}
                          </p>
                          <div className="flex gap-3 items-center lg:flex-row flex-col justify-between w-full">
                            {a.periods?.map((p, i) => {
                              return (
                                <div
                                  className="flex flex-col gap-4 flex-shrink-0 w-fit"
                                  key={`attendanceData-${index}-${i}`}
                                >
                                  <ProgressChart
                                    percentage={
                                      (p.present_count /
                                        (p.present_count +
                                          p.absent_count +
                                          p.unrecorded_count)) *
                                      100
                                    }
                                    title={`Period  ${p.period}:`}
                                  />
                                  <div className="flex items-center w-full justify-center gap-2 text-sm font-bold">
                                    <p>{p.present_count} ✅</p>
                                    <p>|</p>
                                    <p>{p.absent_count} ❌</p>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* FOOTER */}
      <Footer />
    </div>
  );
}
